.btn-icon-action {
  margin-right: 5px !important;
}

.p-fluid .p-dropdown {
  width: 90% !important;
}

span.counterChar {
  font-size: 10px;
}

input.error,
textarea.error,
select.error,
div.error {
  border: 1px solid red;
}

.errorMessage {
  color: red;
  font-size: 0.9em;
  display: relative;
  float: left;
  margin: 5px 0 15px 5px;
}

.logo-page {
  width: 180px;
}

table thead tr th {
  background: #f8b415 !important;
}
