.Header {
  border-top: 1px solid #00000020;
  height: 40px !important;
}

.Footer {
  background: #f8b415 !important;
}

.Table-Header-Cell {
  /*background: #f8b415 !important;*/
  border-style: solid !important;
  border-width: 1px !important;
  border-color: #ff980090 !important;
  background: #f8b415 !important;
  /*height: 0px !important;*/
}

.cell-header {
  display: flex !important;
  justify-content: center !important;
}

.Header svg {
  color: #ff9800 !important;
}

.Button-Border {
  box-shadow: none !important;
  margin: 4px !important;
}
.SnackbarItem-lessPadding-332 {
  background-color: #f8b415 !important;
}

.gXQQfT {
  text-align: center !important;
}

.virtualized-container {
  background-color: white !important;
}

.Table-Row {
  background-color: transparent !important;
}

.virtualized-container > div > div:nth-child(even) {
  background-color: #f2f2f2 !important;
}

.title {
  margin-right: unset !important;
  width: 100% !important;
  justify-content: center !important;
  font-weight: bold !important;
  padding-left: 125px !important;
}

/* Dashboard */
.MuiTabs-flexContainer {
  background-color: white !important;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: #f8b415 !important;
}

@media (min-width: 700px) {
  .col-xl-ajt {
    width: calc(100% / 8) !important;
  }
}
.MuiTab-wrapper {
  font-size: 12px;
}
.MuiTab-wrapper {
  display: flex !important;
  flex-direction: row-reverse !important;
}
.MuiTab-wrapper svg {
  margin-left: 4px;
  height: 18px;
}

.Table-Cell {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.Table-Row {
  height: 0px !important;
}

$n: 100;

.virtualized-container {
  height: 455px !important;
  > div {
    height: 400px !important;
    @for $i from 1 through $n {
      > div:nth-child(#{$i}) {
        height: 40px !important;
        top: calc((#{$i} - 1) * 40px) !important;

        > div {
          height: 40px !important;
        }
      }
    }
  }
}

.Table-Header-Cell.action > div {
  width: 120px !important;
}

.Table-Cell.action > div {
  width: 120px !important;
}

.virtualized-container > div > div:nth-child(even),
.virtualized-container > div > div:nth-child(even) > .Table-Row > .Table-Cell.action,
.virtualized-container > div > div:nth-child(even) > .Table-Row {
  background-color: #f2f2f2 !important;
}

.p-multiselect {
  width: 100% !important;
  height: 30px !important;
}

.p-multiselect-label {
  white-space: nowrap !important;
  max-width: 595px !important;
}

.p-calendar > input {
  height: 30px;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.p-inputtext {
  width: 100% !important;
  // height: 30px !important ;
}

body .p-dropdown {
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  height: 30px;
}

.p-placeholder {
  font-size: 12px !important;
  align-items: center !important;
  display: flex !important;
}

// Mais filtros
.MuiAccordionDetails-root {
  background-color: #f2f2f2;
}
.MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded {
  background-color: #00000020;
  min-height: 0px !important;
  height: 30px !important;
  border-radius: 4px !important;
}

.MuiButtonBase-root.MuiAccordionSummary-root {
  background-color: #00000030;
  height: 30px !important;
  min-height: 0px !important;
  border-radius: 4px !important;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.MuiTypography-body1 {
  font-size: 14px !important;
  min-width: 50% !important;
}
.p-dropdown label.p-dropdown-label {
  font-size: 12px !important;
  height: 28px !important;
}

.p-dropdown {
  height: 30px !important;
}

//Alinhamento da tela de perfil
.p-fieldset-content {
  display: flex;
  min-width: 400px;
  width: 100%;
}

body .p-dialog {
  padding: 0;
  background-color: #ffffff;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-dialog .p-dialog-titlebar .p-dialog-title {
  margin: 0;
  padding-left: 24px;
  float: none;
  font-size: 1.286em;
  font-weight: 700;
}

body .p-dialog .p-dialog-titlebar {
  border: 0 none;
  background-color: #f8b415;
  color: #212121;
  padding: 0.5em;
  font-weight: 700;
}
.p-progress-spinner-circle {
  stroke: #828282 !important;
}

.custom-black-tooltip-grfs{
  padding: 7px;
  background-color: #252525;
  font-size: 13px;
}